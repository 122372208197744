import React, { useState, useEffect } from "react";
import InsightsPageContainer from "../../containers/InsightsPageContainer";
import PageBanner from "../../sections/PageBanner";
import InsightSlider from "../../sections/InsightSlider";
import Insights from "../../sections/Insights";

const InsightsPage = () => {
  const [featuredData, setData] = useState(null);
  const fetchURL =
    "https://blog.everestek.com/ghost/api/v2/content/posts/?key=cd6951ab0e3a27a612721f3427&filter=tag:featured&fields=title,url,feature_image,custom_excerpt";
  const getData = () => fetch(`${fetchURL}`).then((res) => res.json());

  useEffect(() => {
    getData().then((featuredData) => setData(featuredData));
  }, []);
  return (
    <InsightsPageContainer>
      {(insightsData) => {
        const pageId = "insights";
        const insightsContent = insightsData.find(
          (data) => data.pageId === pageId
        );
        const { pageBanner } = insightsContent;

        return (
          <>
            {pageBanner && !pageBanner.hideSection && (
              <PageBanner
                bannerContent={pageBanner.textContent}
                bgImage={pageBanner.backgroundImage}
              />
            )}
            {featuredData && featuredData.posts && (
              <InsightSlider data={featuredData.posts} page={pageId} />
            )}
            <Insights
              containerSx={{
                px: [null, null, null, 4],
              }}
            />
          </>
        );
      }}
    </InsightsPageContainer>
  );
};

export default InsightsPage;
