import React from "react";
import { Router } from "@reach/router";
import InsightsPage from "../components/InsightsPage";
import NotFoundPage from "./404";

const insightsPageRouter = (props) => (
  <Router>
    <InsightsPage {...props} path="/insights" />
    <NotFoundPage default />
  </Router>
);

export default insightsPageRouter;
