import PropTypes from "prop-types";
import React from "react";
import Box from "../../base/Box";
import Carousel from "../../components/Carousel";
import AspectRatio from "../../components/AspectRatio";
import Section from "../../components/Section";
import ActionButtons from "../../components/ActionButtons";
import CustomArrow from "../../components/Carousel/CustomArrow";

const InsightSlider = ({ sectionSx, data, containerSx }) => {
  const prevStyled = {
    left: "-6rem",
  };
  const nextStyled = {
    right: "-6rem",
  };
  const controlStyled = {
    position: "absolute",
    top: "50%",
  };

  return (
    <Section
      containerSx={{
        maxWidth: [null, "maxWidths.sm", "maxWidths.md", "maxWidths.lg"],
        ...containerSx,
      }}
      sx={{
        bg: "white",
        ...sectionSx,
      }}
    >
      <Box
        as="h2"
        sx={{ color: "primary", fontWeight: "medium", mb: 3 }}
        variant="markdown.h2"
      >
        Featured Posts
      </Box>
      {data && data.length > 0 && (
        <Box
          sx={{
            alignItems: "flex-end",
            display: "flex",
            flex: "auto",
            mt: 2,
          }}
        >
          <Carousel
            settings={{
              cssEase: "linear",
              dots: true,
              nextArrow: <CustomArrow nextSx={nextStyled} sx={controlStyled} />,
              prevArrow: (
                <CustomArrow prev prevSx={prevStyled} sx={controlStyled} />
              ),
              responsive: [
                {
                  breakpoint: 992,
                  settings: {
                    arrows: false,
                  },
                },
              ],
              slideShow: 1,
              slidesToScroll: 1,
              swipeToSlide: false,
            }}
            sx={{ "&.slick-slider .slick-dots": { textAlign: "left" } }}
          >
            {data.map((data, index) => (
              <Box key={`${data.feature_image}-${index}`}>
                <AspectRatio
                  ratio={[[1, 1], [4, 3], null, [16, 9]]}
                  sx={{
                    maxWidth: "100%",
                  }}
                >
                  <Box
                    sx={{
                      backgroundImage: `linear-gradient(to right, rgba(8, 68, 111, 1), rgb(0 0 0 / 0.04)), url("${data.feature_image}")`,
                      backgroundPosition: "center center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      bottom: 0,
                      left: 0,
                      position: "absolute",
                      right: 0,
                      top: 0,
                    }}
                  />
                  <Box
                    sx={{
                      bottom: 0,
                      display: "flex",
                      justifyContent: "flex-start",
                      left: 0,
                      position: "absolute",
                      right: 0,
                      top: 0,
                    }}
                  >
                    <Box
                      sx={{
                        color: "white",
                        px: 4,
                        width: [null, null, null, "70%"],
                      }}
                    >
                      <Box as="h2" sx={{ fontSize: [3, null, null, 5] }}>
                        {data.title}
                      </Box>
                      <Box as="p">{data.custom_excerpt}</Box>
                    </Box>
                  </Box>
                  <ActionButtons
                    buttons={[
                      {
                        buttonLabel: "Read the Post",
                        buttonPath: `${data.url}`,
                        buttonType: "secondary",
                        externalLink: true,
                      },
                    ]}
                    wrapperSx={{
                      bottom: 0,
                      left: "auto",
                      position: "absolute",
                      right: 0,
                      top: "auto",
                    }}
                  />
                </AspectRatio>
              </Box>
            ))}
          </Carousel>
        </Box>
      )}
    </Section>
  );
};

InsightSlider.propTypes = {
  containerSx: PropTypes.shape({}),
  data: PropTypes.PropTypes.arrayOf({
    custom_excerpt: PropTypes.string,
    feature_image: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  sectionSx: PropTypes.shape({}),
};

InsightSlider.defaultProps = {
  containerSx: {},
  sectionSx: {},
};

export default InsightSlider;
