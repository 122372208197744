import PropTypes from "prop-types";
import React from "react";
import { StaticQuery, graphql } from "gatsby";

const InsightsPageContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allInsightsJson {
          edges {
            node {
              id
              pageId
              pageTitle
              pageBanner {
                hideSection
                textContent {
                  heading
                  content
                }
                backgroundImage
              }
              insights {
                hideSection
                textContent {
                  heading
                  content
                }
                actionButtons {
                  hideButton
                  simpleLink
                  externalLink
                  buttonType
                  buttonLabel
                  buttonPath
                }
              }
            }
          }
        }
      }
    `}
    render={({ allInsightsJson: { edges: insightsData } }) =>
      children(
        insightsData.map((e) => ({
          ...e.node,
        }))
      )
    }
  />
);

InsightsPageContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default InsightsPageContainer;
